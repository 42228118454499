export const SidebarMenu = [
    {
      title: "GIA Summary",
      id: "giaSummeryBox",
    },
    {
      title: "Origin/Mine",
      id: "originBox",
    },
    {
      title: "Cause of Color",
      id: "colorCauseBox",
    },
    {
      title: "Saturation",
      id: "saturationBox",
    },
    {
      title: "Shape",
      id: "shapeBox",
    },
    {
      title: "Face-up",
      id: "surfaceAreaBox",
    },
    {
      title: "Weight",
      id: "weightBox",
    },
    {
      title: "Clarity",
      id: "clarityBox",
    },
    {
      title: "Fluorescence",
      id: "fluorescenceBox",
    },
    {
      title: "Rarity",
      id: "rarityBox",
    },
    {
      title: "Index",
      id: "indexBox",
    }
  ];

  export const initialSidebar = {
    giaSummeryBox: true,
    originBox: true,
    shapeBox: true,
    colorCauseBox: true,
    weightBox: true,
    clarityBox: true,
    saturationBox: true,
    fluorescenceBox: true,
    surfaceAreaBox: true,
    rarityBox: true,
    indexBox: true,
    luxuryHistoryBox: true,
  };
  
  export const initialSidebarFalse = {
    giaSummeryBox: false,
    originBox: false,
    shapeBox: false,
    colorCauseBox: false,
    weightBox: false,
    clarityBox: false,
    saturationBox: false,
    fluorescenceBox: false,
    surfaceAreaBox: false,
    rarityBox: false,
    indexBox: false,
    luxuryHistoryBox: false,
  };

  export const sidebarMenuSliceNum = 1;

  export const getDefaultSideBarMenuArray = (sidebarCardObj)=>{
    const displaySettings = [];
    const hiddenSettings = [];
    if(sidebarCardObj && Object.keys(sidebarCardObj).length > 0){
    SidebarMenu.slice(sidebarMenuSliceNum).forEach(menuItem => {
      if(sidebarCardObj[menuItem.id]){
        displaySettings.push(menuItem.title);
      }else{
        hiddenSettings.push(menuItem.title);
      }
     });
    }
    return {displaySettings, hiddenSettings}
  }

  export const getSideBarMenuArray = (sidebarCardObj,showCardId)=>{
    const displaySettings = [];
    const hiddenSettings = [];
    if(sidebarCardObj && Object.keys(sidebarCardObj).length > 0){
    SidebarMenu.slice(sidebarMenuSliceNum).forEach(menuItem => {
      if(sidebarCardObj[menuItem.id]){
        displaySettings.push(menuItem.title);
      }else{
        if(showCardId.includes(menuItem.id)){
          hiddenSettings.push(menuItem.title);
        }
      }
     });
    }
    return {displaySettings, hiddenSettings}
  }

  export const newSideBarValidate =(sidebardCardObj, diaryInfoData, data, gia_report, range_slider)=>{
    const {originInfo,shapInfo,profilerInfo,florescenceInfo,weightInfo,saturationInfo,clarityInfo,rarityInfo,colorInfo,famousDiamondInfo,appreciation} = diaryInfoData || {};
    const {
      giaSummeryBox, originBox, shapeBox, colorCauseBox, weightBox, clarityBox, saturationBox, fluorescenceBox, surfaceAreaBox, indexBox, luxuryHistoryBox,rarityBox
    } = sidebardCardObj
    let obj = { giaSummeryBox};
    obj.originBox = originBox && originInfo?.C46 !== "#N/A"? true : false;
    obj.shapeBox = shapeBox &&  shapInfo?.C24 !== "#N/A" ? true : false;
    obj.surfaceAreaBox = surfaceAreaBox && profilerInfo?.C58 !== "#N/A"? true: false;
    obj.fluorescenceBox = fluorescenceBox && (gia_report?.results?.fluorescence !== "None" && florescenceInfo?.C42 !== "#N/A") ? true: false;
    obj.weightBox = weightBox && weightInfo?.C34 !== "#N/A" ? true:false;
    obj.saturationBox = saturationBox && saturationInfo?.C30 !== "#N/A" ? true:false;
    obj.clarityBox = clarityBox && clarityInfo?.C38 !== "#N/A" ? true:false;
    obj.rarityBox =rarityBox &&  range_slider?.showRarityCard === "TRUE" && (rarityInfo?.C62 !== "#N/A" || rarityInfo?.C64 !== "#N/A") !== "#A" ? true:false;
    obj.colorCauseBox = colorCauseBox && colorInfo?.C18 !== "#N/A" ? true : false;
    obj.luxuryHistoryBox = luxuryHistoryBox && !(famousDiamondInfo?.C50 === "#N/A" ||famousDiamondInfo?.C50?.includes("#NUM!") ||!famousDiamondInfo) ? true:false;
    obj.indexBox = indexBox && ((data?.color === "Blue" || data?.color === "Pink" || data?.color === "Yellow" || data?.color === "Pink - Purplish Pink" || data?.color === "Pink/Purple/ish Pink") && (data?.saturation === "Fancy" ||   data?.saturation === "Fancy Intense" ||   data?.saturation === "Fancy Vivid") && parseFloat(data?.weight) >= 1 && appreciation?.C72 !== "#N/A" ) ? true :false;
    return obj;
  }

  export const getMatchingTitles=(givenObject)=> {
    return Object.values(givenObject)
      .map((id) => SidebarMenu.find((item) => item.id === id))
      .filter((item) => item !== undefined)
      .map((item) => item.title);
  }