/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";
import FcrfContext from "../../Context";
import { SidebarMenu } from "../../utils/sidebarMenuItems";



const Sidebar = ({ handleSideBarCard, openSidebar, setOpenSidebar }) => {
  const getContext = useContext(FcrfContext);
  const {
    sideBarNone,
    sidebarCard,
    activeLink,
   
    setActiveLink,
    sidebarCardMainCopy
  } = getContext;

  const [sideBarMenuOptions, setSideBarMenuOptions] = useState(SidebarMenu);
  const handleClick = (id) => {
    const position = document.getElementById(id)?.getBoundingClientRect();
    window.scrollTo({
      left: position.left,
      top: position.top + window.scrollY - 80,
    });
  };

  const sections = document.querySelectorAll("section[id]") || [];
  const NavIds123 = [];
  const NavIds = document.getElementsByClassName("diary-card") || [];

  useEffect(() => {
    window.addEventListener("scroll", navHighlighter);
    return () => {
      window.removeEventListener("scroll", navHighlighter);
    };
  }, [sections]);

  function navHighlighter() {
    let scrollY = window.pageYOffset;
    sections.length > 0 &&
      sections.forEach((current) => {
        const sectionHeight = current.offsetHeight;
        const sectionTop =
          current.offsetTop - (window.scrollY > 2000 ? 300 : 120);
        const sectionId = current.getAttribute("id");

        if (scrollY >= sectionTop && scrollY <= sectionTop + sectionHeight) {
          ActiveSideMenu(sectionId);
        }
      });
  }

  const ActiveSideMenu = (id) => {
    const activeId = SidebarMenu.filter((elem) => elem?.id === id);
    setActiveLink(activeId[0]?.title);
  };

  for (let i = 0; i < NavIds.length; i++) {
    let NavIds12 = NavIds[i].getAttribute("id");
    NavIds123.push(NavIds12);
  }

  

  const handleSidebarOpen = () => {
    setOpenSidebar(!openSidebar);
  };

  useEffect(() => {
   
    const filteredSidebarMenu = SidebarMenu.filter(menuItem => sidebarCard[menuItem.id]);
    setSideBarMenuOptions(filteredSidebarMenu);
  }, [sidebarCardMainCopy])

  return (
    <div className={`sidebar-wrapper ${openSidebar ? "open" : "close"}`}>
      <div className={`sidebar ${openSidebar ? "open" : "close"}`}>
        <div className="sidebar-menu">
          <div
            className={`sidebar-toggle-btn ${openSidebar ? "" : "close"}`}
            onClick={() => handleSidebarOpen()}
            role="presentation"
          >
            <div className={`sidebar-toggle ${openSidebar ? "open" : ""}`}>
              <div className="bars-sidebar" id="bar1" />
              <div className="bars-sidebar" id="bar2" />
              <div className="bars-sidebar" id="bar3" />
            </div>
          </div>
          <div className="sidebar-menu-list">
            {sideBarMenuOptions.map((e, i) => (
              <div
                className={`diary-nav-item ${
                  NavIds123.find((elem) => elem === e?.id) ? "show" : "hide"
                }`}
                key={i}
              >
                <span
                  className={`diary-link ${
                    activeLink === e?.title ? "active" : ""
                  } `}
                  onClick={() => handleClick(e?.id)}
                  role="presentation"
                >
                  {e?.title === sideBarNone ? "NONE" : e?.title}
                </span>
                {e?.id !== "giaSummeryBox" && (
                  <label className="diary-check-container">
                    <input
                      type="checkbox"
                      id={`nav-${e?.id}`}
                      onChange={() =>
                        handleSideBarCard(e.id, sidebarCard[e.id])
                      }
                      checked={sidebarCard[e.id]}
                    />
                    <div className="checkmark"></div>
                  </label>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
