import { useContext, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import DiaryLayout from "../layout/diaryLayout";
import FcrfContext from "../../Context";
import DiaryGIAInput from "./diaryGIAInput";
import Loader from "../loader/loader";
import { withAuth } from "../../config/axios";
import DiaryNotice from "../Models/diaryNotice";
import PageLoader from "../loader/pageLoader";
import DisclaimerPopup from "../Models/disclaimer";
import VerticalDropdown from "../verticalDropdown/verticalDropdown";
import HeaderImagedata from "../../imageJSON/desktopImage.json";
import {
  HistoryIcons,
  SaveIcons,
  SettingsIcons,
} from "../verticalDropdown/svgData";
import { getFormattedIsoDate } from "../../utils/dateUtils";
const DiaryInputPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const getContext = useContext(FcrfContext);
  const {
    setInputError,
    setIsWp,
    setIsOriginOption,
    setDateSetting,
  } = getContext;
  const initialState = {
    report_number: "",
    mine: "",
  };
  const [payload, setPayload] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const [loaderType, setLoaderType] = useState("loginalert");
  const [isCheckShapeColoe, setIsCheckShapeColoe] = useState(false);
  const { from, ischeck, type  } = location.state ?? {};
  const [isActive, setIsActive] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [giainputTextError, setGiainputTextError] = useState(false);
  const [deskTopBanner, setDeskTopBanner] = useState(null);
  const [mobileBanner, setMobileBanner] = useState(null);
  const [loader, setLoader] = useState(true);
  const handleGIAInput = (value, name, step) => {
    let myArr = String(value)
      .split("")
      .map((num) => {
        return Number(num);
      });
    if (myArr.length >= 7 && myArr.length <= 10) {
      setInputError(false);
      setGiainputTextError(false);
    } else if (value.length > 10) {
      setGiainputTextError(true);
      setInputError(true);
    } else {
      setGiainputTextError(false);

      setInputError(true);
    }
    setPayload({ ...payload, [name]: value });
  };
  const handleGIAMine = (e) => {
    setPayload({ ...payload, mine: e?.target?.value });
    setIsOriginOption(e?.target?.value);
  };
  const [disclaimerError, setDisclaimerError] = useState("");

  const updateDisclaimer =async () => {
    setBtnLoading(true);
    console.log("disclaimerInput", disclaimerInput);
    if (disclaimerInput) {
      const updated = await withAuth().put(`users/update-disclaimer`);
      if (updated.data.status === 200) {
        setBtnLoading(false);
        setShowDiaryDisclaimer(false);
      }
    } else {
      setDisclaimerError("Accept the disclaimer");
      setBtnLoading(false);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsActive(true);
    setLoading(true)
    setLoaderType("progress")

    if (
      localStorage.getItem("fcrf@token") === "undefined" ||
      !localStorage.getItem("fcrf@token")
    ) {
      setLoading(true);
      return;
    }

    try {
      const data = {
        ...payload,
        free: localStorage.getItem("is_free")
          ? localStorage.getItem("is_free")
          : false,
      };
      const response = await withAuth().post("diamond/diamond_diary", data);
      const {
        status,
        msg,
        report,
        attemptedcount,
        Id,
        type,
        postId,
        rairtyPostID,
        createdAt,
        raritypdfUrl,
        rairtyID,
      } = response.data || {};
      console.log(status, msg);

      if (msg === "diary_disclaimer_accepted") {
        setShowDiaryDisclaimer(true);
        setIsActive(false);
        return;
      }



      if (status === 401) {
        setLoaderType(msg);
        setLoading(true);
      }


      if (status === 203) {
        setLoading(false);
        setIsActive(false);
        navigate("/diary", {
          state: { from: "diaryreport", ischeck: true, type },
        });
      }
      if (status === 200) {
        const isoDate = getFormattedIsoDate(report?.diaryInfo?.rarityInfo?.E68);
        setDateSetting({
          stringDate: report?.diaryInfo?.rarityInfo?.E68,
          isoDate: isoDate,
        });

        const obj = {
          Id,
          postId,
          rairtyPostID,
          raritypdfUrl,
          rairtyID,
        };
        localStorage.setItem("dairyattemptedcount", attemptedcount);
        navigate("/diary-report", {
          state: {
            Id: Id,
            postId: postId,
            report: report,
            action: "Create",
            obj: obj,
            createdAt: createdAt,
          },
        });
      }
    } catch (e) {

      if(e.response?.status === 403){
        setLoaderType(e.response?.data?.msg);
          setLoading(true);
      }else{
        setLoading(false);
        console.log("handleSubmit error", e);
      }

    }
  };
  const [showDiaryDisclaimer, setShowDiaryDisclaimer] = useState(false);
  const [disclaimerInput, setDisclaimerInput] = useState(false);

  useEffect(() => {
    if (loader) {
      const interval = setInterval(() => {
        window.parent.postMessage("iframeIsReadyToReceiveUserData", "*");
      }, 1000);

      const handleMessage = (event) => {
        console.log("event.origin", event.origin);
        console.log("event.origin2", process.env.REACT_APP_WP_BASE_URL);
        if (event.origin === process.env.REACT_APP_WP_BASE_URL) {
          const {
            is_free,
            token,
            is_wp,
          } = event?.data;

          setIsWp(is_wp);
          console.log("token come form wp diary==>", token);
          console.log("event?.data==>", event?.data);

          if (token) {
            localStorage.setItem("is_free", is_free ? is_free : false);
            localStorage.setItem("fcrf@token", token);
            setLoader(false);
          } else {
            localStorage.removeItem("fcrf@token");
          }
        } else {
          if (!localStorage.getItem("fcrf@token")) {
            if (window.location !== window.parent.location) {
              // The page is in an iframe
            } else {
              // The page is not in an iframe
              window.location.href = process.env.REACT_APP_WP_APP_LOGIN;
            }
          }
        }

        if (event?.data) {
          clearInterval(interval);
        }
      };

      window.addEventListener("message", handleMessage, false);

      return () => {
        window.removeEventListener("message", handleMessage);
        clearInterval(interval);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, loader]);

  const props = {
    payload,
    handleGIAInput,
    handleSubmit,
    handleGIAMine,
    isActive,
    giainputTextError,
    loader,
  };

  useEffect(() => {
    if (from === "diaryreport" && ischeck) {
      setIsCheckShapeColoe(true);
      navigate("/diary", {
        state: { from: "", ischeck: false, type },
      });
    }
    // eslint-disable-next-line
  }, [from, ischeck, navigate, type]);

  const handleClose = () => {
    setLoading(false);
    setIsActive(false);
  };
  const handleCheckLogin = () => {
    console.log("okkk login no");
    setLoading(true);
    return;
  };
  const dropdownItems = [
    {
      icon: HistoryIcons,
      route: "/diary-history-report",
      label: "Search History",
    },
    { icon: SaveIcons, route: "/diary-save-history", label: "Saved Reports" },
    { icon: SettingsIcons, route: `/settings`, label: "Report Settings" },
  ];

  const [accordianShow, setAccordianShow] = useState(false);

  const accordianOpen = () => {
    setAccordianShow(true);
  };
  const accordianClose = () => {
    setAccordianShow(false);
  };

  useEffect(() => {
    const desktopImages = HeaderImagedata?.desktopimages;
    const mobileImages = HeaderImagedata?.mobileimages;
    if (desktopImages.length > 0) {
      const randomDesktopImage =
        desktopImages[Math.floor(Math.random() * desktopImages.length)];
      setDeskTopBanner(randomDesktopImage);
    }

    if (mobileImages.length > 0) {
      const randomMobileImage =
        mobileImages[Math.floor(Math.random() * mobileImages.length)];
      setMobileBanner(randomMobileImage);
    }
  }, []);

  return (
    <>
      {isActive === "teste" && <PageLoader />}
      <DiaryLayout
        sideBar={false}
        header={true}
        from="inputpage"
        handleCheckLogin={handleCheckLogin}
        className="header-remove-mob"
      >
        {loading && (
          <Loader type={loaderType} handleClose={handleClose} from="diary" />
        )}
        <div className="diary-input-page">
          <div className="container">
            <div className="rarity-access-content  diary-input-content diary-input-mob">
              <div className="diary-title diary-title-mt w-100">
                <h1>
                  Diamond diary<span className="sup-text">™</span>{" "}
                  <span className="beta-top">[BETA]</span>
                </h1>
                <VerticalDropdown
                  handleCheckLogin={handleCheckLogin}
                  dropdownItems={dropdownItems}
                  loader={loader}
                />
              </div>

              <div
                className="w-100 dairy-banner dairy-banner-shadow"
                style={{
                  backgroundImage: `url(${
                    deskTopBanner
                      ? deskTopBanner
                      : "/diaryImage/desktop-header-1.jpg"
                  })`,
                }}
              >
                <h1>
                  <span>present</span> the story not just a gem
                </h1>
              </div>
              <div
                className="w-100 dairy-banner diary-mob-banner dairy-banner-shadow"
                style={{
                  backgroundImage: `url(${
                    mobileBanner
                      ? mobileBanner
                      : "/diaryImage/diamond-diary-page-mobile-photo1.jpg"
                  })`,
                }}
              >
                  <h1>
                      <span>present</span> the story not just a gem
                    </h1>
                  </div>
              <DiaryGIAInput {...props} />
              <div className="diary-input-desc diary-input-desc-pd  w-100">
                {!accordianShow && (
                  <div
                    onClick={accordianOpen}
                    className="accordian-down-header"
                  >
                    <ArrowSvg />
                    <p>About The Diary Report</p>
                  </div>
                )}
                {accordianShow && (
                  <div>
                    <p>
                      The Diamond Diary report was created to showcase the
                      unique story of a fancy color diamond, complete with
                      images and information about the mine, rarity, weight to
                      face-up ratio, iconic figures associated with similar
                      diamonds, and much more. This report's data output is the
                      result of years of experience and serves as an additional
                      layer of information for evaluating fancy color diamonds.
                      Above all, retailers use the Diary as a talking-points
                      report that provides relevant information to fancy color
                      diamond collectors. The Diary's primary goal is to help
                      retailers demonstrate professionalism and achieve trust.
                    </p>
                    <div className="arrow-up" onClick={accordianClose}>
                      <ArrowSvg />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <DiaryNotice
          show={isCheckShapeColoe}
          setShow={setIsCheckShapeColoe}
          type={type}
          giaNumber={payload?.report_number}
          reportType="diary"
        />
      </DiaryLayout>
      {showDiaryDisclaimer && (
        <DisclaimerPopup
          show={showDiaryDisclaimer}
          setShow={setShowDiaryDisclaimer}
          disclaimerInput={disclaimerInput}
          setDisclaimerInput={setDisclaimerInput}
          updateDisclaimer={updateDisclaimer}
          disclaimerError={disclaimerError}
          btnLoading={btnLoading}
        />
      )}
    </>
  );
};

export default DiaryInputPage;

const ArrowSvg = () => {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 109.28 90.9"
    >
      <path d="M89.6,21.81a3.69,3.69,0,0,0-3.21-5.53l-31.76.11-31.77.11a3.69,3.69,0,0,0-3.17,5.55l16,27.45L48,70.72a7.86,7.86,0,0,0,13.61,0l12.2-21.3Z" />
    </svg>
  );
};
